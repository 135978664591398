
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import VinculoIntegracao from '@/components/Vinculo/VinculoIntegracao.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoEstoque from '@/servicos/Cadastros/ServicoEstoque';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import CampoCep from '@/core/components/Tela/CampoCep.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import { ETipoVinculo } from '@/models/Enumeradores/ETipoVinculo';

export default defineComponent({
  name: 'EstoqueModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    RequisicaoModal,
    CampoCep,
    SelecionarSimNao,
    VinculoIntegracao,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemSucesso } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoEstoque = new ServicoEstoque();
    telaBase.identificadorRecurso = 'CADASTRO_ESTOQUES';

    const state = reactive({
      estoque: {} as IEstoque,
    });

    function objetoInicial() {
      state.estoque = {} as IEstoque;
      state.estoque.codigo = 0;
      state.estoque.padrao = false;
      state.estoque.virtual = false;
      state.estoque.ativo = true;
      state.estoque.vinculos = [];
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso();

      if (state.estoque.codigo === 0) {
        retorno = await servicoEstoque.incluir(state.estoque);
      } else {
        retorno = await servicoEstoque.alterar(state.estoque);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (state.estoque.codigo === 0) {
          sincronizarRegistro(retorno.codigoRegistro);
        } else {
          sincronizarRegistro(props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          defineNovaOperacao(props.operacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        defineTextoPadraoBotoes((props.operacao.codigoRegistro === 0));
        if (props.operacao.codigoRegistro > 0) {
          state.estoque = await servicoEstoque.obter(props.operacao.codigoRegistro);
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      ETipoVinculo,
    };
  },
});
