import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-772d57ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { style: {"display":"inline-flex","gap":"4px"} }
const _hoisted_4 = { class: "ss-integracao-detalhes" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "ant-row" }
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-21" }
const _hoisted_8 = ["onUpdate:modelValue"]
const _hoisted_9 = { class: "ant-col ant-col-xs-24 ant-col-xl-3" }
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_icone = _resolveComponent("icone")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_a_collapse = _resolveComponent("a-collapse")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.UtilitarioGeral.validaLista(_ctx.state.integracoes))
      ? (_openBlock(), _createBlock(_component_a_alert, {
          key: 0,
          message: "Você não possui nenhuma integração para criar os vínculos.",
          banner: "",
          closable: ""
        }))
      : (_openBlock(), _createBlock(_component_card, {
          key: 1,
          titulo: _ctx.props.titulo
        }, {
          default: _withCtx(() => [
            (_ctx.UtilitarioGeral.validaLista(_ctx.state.integracoesDisponiveisParaVinculo))
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.state.integracoesDisponiveisParaVinculo, (integracao, index) => {
                  return (_openBlock(), _createElementBlock("button", {
                    type: "button",
                    key: 'INT-DISP' + index,
                    class: "ant-btn ant-btn-secondary ss-tamanho-completo",
                    style: {"display":"inline-flex","gap":"4px"},
                    title: "Adicionar",
                    onClick: ($event: any) => (_ctx.adicionarVinculo(integracao.tipo, integracao.codigo, index))
                  }, [
                    _createElementVNode("img", {
                      class: "ss-imagem-responssiva",
                      style: {"max-height":"20px"},
                      src: _ctx.servicoIntegracao.obtemLogoIntegracao(integracao.tipo)
                    }, null, 8, _hoisted_2),
                    _createElementVNode("span", _hoisted_3, "Criar vínculo " + _toDisplayString(_ctx.apresentarTextoAdicionarVinculo(integracao)), 1)
                  ], 8, _hoisted_1))
                }), 128))
              : _createCommentVNode("", true),
            _createVNode(_component_a_form, {
              layout: "vertical",
              style: {"margin-top":"20px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_collapse, {
                  activeKey: _ctx.state.vinculoEmVisualizacao,
                  "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.vinculoEmVisualizacao) = $event)),
                  accordion: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedVinculos, (vinculo, index) => {
                      return (_openBlock(), _createBlock(_component_a_collapse_panel, { key: index }, {
                        header: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("img", {
                              class: "ss-imagem-responssiva",
                              style: {"max-height":"20px"},
                              src: _ctx.servicoIntegracao.obtemLogoIntegracao(vinculo.tipoIntegracao)
                            }, null, 8, _hoisted_5),
                            _createElementVNode("span", null, _toDisplayString(_ctx.apresentarTituloVinculo(vinculo)), 1)
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                              _createVNode(_component_a_form_item, {
                                label: "Código de Identificação",
                                class: "ss-margin-campos"
                              }, {
                                default: _withCtx(() => [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "text",
                                    maxlength: "255",
                                    class: "ant-input",
                                    "onUpdate:modelValue": ($event: any) => ((vinculo.codigoIntegracao) = $event)
                                  }, null, 8, _hoisted_8), [
                                    [_vModelText, vinculo.codigoIntegracao]
                                  ])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_a_form_item, {
                                label: " ",
                                class: "ss-margin-campos"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("button", {
                                    type: "button",
                                    class: "ant-btn ant-btn-secondary ss-tamanho-completo",
                                    title: "Remover",
                                    onClick: ($event: any) => (_ctx.removerVinculo(index))
                                  }, [
                                    _createVNode(_component_icone, { nome: "excluir" })
                                  ], 8, _hoisted_10)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["activeKey"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["titulo"]))
  ]))
}