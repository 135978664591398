export enum ETipoVinculo {
    Categoria = 1,
    Marca = 2,
    Caracteristicas = 3,
    ItemCaracteristica = 4,
    Estoques = 5,
    ItemEstoque = 6,
    Produto = 7,
    ProdutoCaracteristicas = 8,
    ProdutoDefinicoes = 9,
    ProdutoImagens = 10,
    AnuncioVariacoes = 11
}