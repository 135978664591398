import ApiSistema from '@/core/conectores/ApiSistema';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import { IEstoqueItem } from '@/models/Entidades/Cadastros/Estoques/IEstoqueItem';
import { IDTOEstoque } from '@/models/DTO/Cadastros/Estoques/IDTOEstoque';
import { IDTOEstoqueProdutoDefinicao } from '@/models/DTO/Cadastros/Estoques/IDTOEstoqueProdutoDefinicao';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';

/**
 * Serviço de Estoques
 * Fornece todas regras de negócios e lógicas relacionado a Estoques.
 */

class ServicoEstoque implements IServicoBase<IEstoque> {
  endPoint = 'estoques';

  apiSistema = new ApiSistema();

  public requisicaoSistema(): void {
    this.apiSistema = new ApiSistema(true);
  }

  public async obterTodosEstoques(): Promise<IEstoque[]> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/todos`);
    return result.data;
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaPropriedades = await this.apiSistema.obterPropriedadesConsulta(this.endPoint);
    return listaPropriedades;
  }

  public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    return this.apiSistema.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
  }

  public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
    return this.apiSistema.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
  }

  public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOEstoque): string {
    return objeto.descricao;
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
    const parametrosAdicionais = this.apiSistema.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
    const result: any = await this.apiSistema.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
    return result.data;
  }

  public async incluir(objeto: IEstoque): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}`, objeto);
    return result.data;
  }

  public async alterar(objeto: IEstoque): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.put(`${this.endPoint}/${objeto.codigo}`, objeto);
    return result.data;
  }

  public async obter(codigo: number): Promise<IEstoque> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async excluir(codigo: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async incluirItens(codigoEstoque: number, itens: IEstoqueItem[]): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}/${codigoEstoque}/itens`, itens);
    return result.data;
  }

  public async obterItem(codigoItem: number, empresa: number): Promise<IEstoqueItem> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/codigoItem/${codigoItem}/empresa/${empresa}`);
    return result.data;
  }

  public async obterItens(codigoEstoque: number, codigoDefinicoesProdutos?: number[]): Promise<IEstoqueItem[]> {
    let parametrosAdicionais = '';
    if (codigoDefinicoesProdutos !== undefined) {
      codigoDefinicoesProdutos.forEach((codigoDefinicao) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `codigoDefinicoesProdutos=${codigoDefinicao}`;
      });
    }

    const result: any = await this.apiSistema.get(`${this.endPoint}/${codigoEstoque}/itens${parametrosAdicionais}`);
    return result.data;
  }

  public async obterItemEstoqueInformacoesProduto(codigoEstoque: number, codigoProdutoDefinicao: number, codigoUnidade: number, codigoEmpresa: number): Promise<IEstoqueItem> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/${codigoEstoque}/itens/produto/${codigoProdutoDefinicao}/unidade/${codigoUnidade}/empresa/${codigoEmpresa}`);
    return result.data;
  }

  public async obterQuantidadeDisponivelItemEstoque(codigoItemEstoque: number): Promise<number> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/itens/${codigoItemEstoque}/quantidade-disponivel`);
    return result.data;
  }

  public async obterEstoquesDefinicaoProduto(produtoDefinicao: number, empresa: number): Promise<IDTOEstoqueProdutoDefinicao[]> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/produto-definicao/${produtoDefinicao}/empresa/${empresa}`);
    return result.data;
  }

  public montaApresentacaoEstoque(estoque: IEstoque): string {
    return estoque.nome;
  }
}
export default ServicoEstoque;
