
import {
    defineComponent, onBeforeMount, reactive, computed,
} from 'vue';
import { IIntegracao } from '@/models/Entidades/Integracao';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import { IVinculoIntegracao } from '@/models/Entidades/IVinculoIntegracao';
import ServicoIntegracao from '@/servicos/ServicoIntegracao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoIntegracao } from '@/models/Enumeradores/ETipoIntegracao';
import { ETipoVinculo } from '@/models/Enumeradores/ETipoVinculo';

export default defineComponent({
    name: 'VinculoIntegracao',
    props: {
        titulo: {
            type: String,
            default: 'VÍNCULOS',
        },
        tipoVinculo: {
            type: Number,
            required: true,
        },
        codigoRegistro: {
            type: Number,
            required: true,
        },
        vinculos: {
            type: Array as () => IVinculoIntegracao[],
            default: [] as [],
        },
    },
    components: {
        Card,
        Icone,
    },
    emits: ['update:integracoes', 'update:vinculos'],
    setup(props, { emit }) {
        const servicoIntegracao = new ServicoIntegracao();

        const state = reactive({
            carregando: false,
            vinculoEmVisualizacao: 0,
            integracoes: [] as IIntegracao[],
            integracoesDisponiveisParaVinculo: [] as IIntegracao[],
        });

        const computedVinculos = computed({
            get: () => props.vinculos,
            set: (vinculos: IVinculoIntegracao[]) => {
                emit('update:vinculos', vinculos);
            },
        });

        function preparaIntegracoesDisponiveis() {
            state.integracoesDisponiveisParaVinculo = [];
            if (UtilitarioGeral.validaLista(state.integracoes)) {
                state.integracoes.forEach((integracao) => {
                    if (servicoIntegracao.verificaIntegracaoMarketPlace(integracao.tipo)) {
                        if (!computedVinculos.value.some((c) => c.tipoIntegracao === integracao.tipo)) {
                            state.integracoesDisponiveisParaVinculo.push(integracao);
                        }
                    } else {
                        const existeIntegracao = computedVinculos.value.some((c) => c.integracao === integracao.codigo);
                        if (!existeIntegracao) {
                            state.integracoesDisponiveisParaVinculo.push(integracao);
                        }
                    }
                });
            }
        }

        onBeforeMount(async () => {
            state.integracoes = await servicoIntegracao.obterIntegracoes();
            preparaIntegracoesDisponiveis();
        });

        function apresentarTextoAdicionarVinculo(integracao: IIntegracao): string {
            let texto = '';
            if (!servicoIntegracao.verificaIntegracaoMarketPlace(integracao.tipo)) {
                texto = ` - ${integracao.nome}`;
            }
            return texto;
        }

        function apresentarTituloVinculo(vinculo: IVinculoIntegracao): string {
            if (!servicoIntegracao.verificaIntegracaoMarketPlace(vinculo.tipoIntegracao)) {
                const integracao = state.integracoes.find((c) => c.codigo === vinculo.integracao);
                if (integracao !== undefined) {
                    return integracao.nome;
                }
            }
            return '';
        }

        function adicionarVinculo(tipoIntegracao: ETipoIntegracao, integracao: number, index: number) {
            const vinculoIntegracao: IVinculoIntegracao = {} as IVinculoIntegracao;
            vinculoIntegracao.tipoVinculo = props.tipoVinculo;
            vinculoIntegracao.codigoRegistro = props.codigoRegistro;
            vinculoIntegracao.tipoIntegracao = tipoIntegracao;
            vinculoIntegracao.integracao = integracao;
            vinculoIntegracao.codigoIntegracao = '';
            vinculoIntegracao.nomeIntegracao = '';
            vinculoIntegracao.valorIntegracao = '';
            computedVinculos.value.push(vinculoIntegracao);
            state.integracoesDisponiveisParaVinculo.splice(index, 1);
        }

        function removerVinculo(index: number) {
            computedVinculos.value.splice(index, 1);
            preparaIntegracoesDisponiveis();
        }

        return {
            props,
            ETipoIntegracao,
            ETipoVinculo,
            UtilitarioGeral,
            computedVinculos,
            state,
            adicionarVinculo,
            removerVinculo,
            servicoIntegracao,
            apresentarTextoAdicionarVinculo,
            apresentarTituloVinculo,
        };
    },
});
